// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import "./src/main.css"

// Responsive
import "shapes/lib/main.css"

// Animate
import "animate.css/animate.css"

// Sal Animation
import sal from "sal.js"
import "sal.js/dist/sal.css"

// Moment
import moment from "moment"
import "moment/locale/es"

// initialize
sal()
moment().locale("es")


